import request from "@/utils/request";

// 儿童乐园票列表
export const playgroundList = (params) => {
  return request({
    url: "/we_chat/playground/order/list",
    method: "get",
    params,
  });
};
//  儿童乐园票详情
export const playgroundDetail = (params) => {
  return request({
    url: "/we_chat/playground/order/detail",
    method: "get",
    params,
  });
};
//  儿童乐园票-退票
export const playgroundRefund = (params) => {
  return request({
    url: "/we_chat/playground/order/refund",
    method: "post",
    params,
  });
};
// 儿童乐园票二维码
export const playgroundQrcode = (params) => {
  return request({
    url: "/we_chat/playground/order/qrcode",
    method: "get",
    params,
  });
};

// 订单列表-门票
export const ticketOrderList = (params) => {
  return request({
    url: "/we_chat/ticket_order/list",
    method: "get",
    params,
  });
};

// 我的门票订单-退票
export const ticketRefund = (params) => {
  return request({
    url: "/we_chat/ticket_order/refund",
    method: "post",
    params,
  });
};
// 查询我的门票详情
export const ticketsDetail = (params) => {
  return request({
    url: "/we_chat/ticket_order/detail",
    method: "get",
    params,
  });
};
// 门票二维码
export const ticketOrderQrcode = (params) => {
  return request({
    url: "/we_chat/ticket_order/qrcode",
    method: "get",
    params,
  });
};
// 删除我的门票
export const ticketsDel = (orderNo) => {
  return request({
    url: "/we_chat/ticket_order/delete",
    method: "post",
    params: { orderNo },
  });
};
// // 取消预约团票
// export const ticketsCancel = (id) => {
//   return request({
//     url: "/we_chat/tickets/cancel/" + id,
//     method: "get",
//   });
// };
// 影票列表
export const movieList = (params) => {
  return request({
    url: "/we_chat/show_ticket_order/list",
    method: "get",
    params,
  });
};
// 影票详情
export const movieFind = (params) => {
  return request({
    url: "/we_chat/show_ticket_order/find",
    method: "get",
    params,
  });
};
// 整单退票
export const movieRefundAll = (data) => {
  return request({
    url: "/we_chat/show_ticket_order/refundAll",
    method: "post",
    data,
  });
};
// 单个退票
export const movieRefund = (data) => {
  return request({
    url: "/we_chat/show_ticket_order/refund",
    method: "post",
    data,
  });
};
// 影票删除
export const movieDel = (orderNo) => {
  return request({
    url: "/we_chat/show_ticket_order/del",
    method: "get",
    params: { orderNo },
  });
};
// 影票二维码
export const voucherCode = (params) => {
  return request({
    url: "/we_chat/show_ticket_order/voucherCode",
    method: "get",
    params,
  });
};
// 影票二维码 - 门票
export const voucherCodeTicket = (params) => {
  return request({
    url: "/we_chat/show_ticket_order/voucherCodeTicket",
    method: "get",
    params,
  });
};
// 我的活动列表
export const activityList = (params) => {
  return request({
    url: "/we_chat/wx-activity-order/list",
    method: "get",
    params,
  });
};
// 活动订单-取消
export const activityCancel = (params) => {
  return request({
    url: "/we_chat/wx-activity-order/cancel",
    method: "get",
    params,
  });
};
// 我的活动详情
export const activityInfo = (params) => {
  return request({
    url: "/we_chat/wx-activity-order/detail",
    method: "get",
    params,
  });
};
// 活动删除
export const activityDel = (id) => {
  return request({
    url: "/we_chat/activity/del/" + id,
    method: "get",
  });
};
// 活动二维码
export const activitySignCode = (params) => {
  return request({
    url: "/we_chat/wx-activity-order/signCode",
    method: "get",
    params,
  });
};

// 支付活动订单
export const activity_order_pay = (data) => {
  return request({
    url: "/we_chat/wx-activity-order/pay",
    method: "post",
    data,
  });
};

// 活动门票二维码
export const activityQrCode = (params) => {
  return request({
    url: "/we_chat/wx-activity-order/ticket/qrCode",
    method: "get",
    params,
  });
};

// 我的临展列表
export const exhibitList = (params) => {
  return request({
    url: "/we_chat/wx-exhibit-order/list",
    method: "get",
    params,
  });
};
// 临展订单-取消
export const exhibitCancel = (params) => {
  return request({
    url: "/we_chat/wx-exhibit-order/cancel",
    method: "get",
    params,
  });
};
// 我的临展详情
export const exhibitInfo = (params) => {
  return request({
    url: "/we_chat/wx-exhibit-order/detail",
    method: "get",
    params,
  });
};
// 临展删除
export const exhibitDel = (id) => {
  return request({
    url: "/we_chat/exhibit/del/" + id,
    method: "get",
  });
};
// 临展二维码
export const exhibitSignCode = (params) => {
  return request({
    url: "/we_chat/wx-exhibit-order/signCode",
    method: "get",
    params,
  });
};

// 支付临展订单
export const exhibit_order_pay = (data) => {
  return request({
    url: "/we_chat/wx-exhibit-order/pay",
    method: "post",
    data,
  });
};

// 临展门票二维码
export const exhibitQrCode = (params) => {
  return request({
    url: "/we_chat/wx-exhibit-order/ticket/qrCode",
    method: "get",
    params,
  });
};