import request from "@/utils/request";

// 获取活动列表
export const activityList = (params) => {
  return request({
    url: `/we_chat/wx-activity/list`,
    method: "get",
    params,
  });
};

// 活动详情
export const activity_find = (params) => {
  return request({
    url: `/we_chat/wx-activity-order/getActivity`,
    method: "get",
    params,
  });
};

// 预约活动
export const activity_book = (data) => {
  return request({
    url: "/we_chat/wx-activity-order/add",
    method: "post",
    data,
  });
};

// 判断年龄是否符合互动要求
export const activity_ageFit = (data) => {
  return request({
    url: "/we_chat/wx-activity-order/ageFit",
    method: "post",
    data,
  });
};

// 获取临展列表
export const exhibitList = (params) => {
  return request({
    url: `/we_chat/wx-exhibit/list`,
    method: "get",
    params,
  });
};

// 临展详情
export const exhibit_find = (params) => {
  return request({
    url: `/we_chat/wx-exhibit-order/getExhibit`,
    method: "get",
    params,
  });
};

// 添加临展订单
export const exhibit_book = (data) => {
  return request({
    url: "/we_chat/wx-exhibit-order/add",
    method: "post",
    data,
  });
};

// 判断年龄是否符合互动要求
export const exhibit_ageFit = (data) => {
  return request({
    url: "/we_chat/wx-exhibit-order/ageFit",
    method: "post",
    data,
  });
};